import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quoteId"];

  connect() {
    console.log("olá")
  }

  setQuoteId(event) {
    console.log(event.target.getAttribute("value"))
    const id = event.target.getAttribute("value");
    this.quoteIdTarget.value = id;
  }
}
